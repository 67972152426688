import React, { useEffect } from 'react'
import { gsap } from "gsap"
import anime from 'animejs'
require ('./big-logo-loader.scss')

const BigLogoLoader = props => {
    // constructor(props) {
    //     super(props)
    //     this.state = {}
    //     this.tl = new gsap.timeline({ repeat: -1 })
    //     this.tl2 = new gsap.timeline({ repeat: -1 })
    // }

    useEffect(()=>{

      const tl = new gsap.timeline({ repeat: -1 })
      const tl2 = new gsap.timeline({ repeat: -1 })

      const selector1 = '.large-select-1'
      const selector2 = '.large-select-0, .large-select-2'

    //pulsate animation
    //   tl.set(selector1, { autoAlpha: 0.7 })
    //     .to(selector1, 0.5, {
    //       scale: 0.8,
    //       transformOrigin: '50% 50%'
    //     })
    //     .to(selector1, 0.5, { scale: 1,  transformOrigin: '50% 50%' })

      //page turn
      // tl.from(selector1, 1, { scaleX: 0, transformOrigin: "left" })
      // tl.to(selector1, 1, { scaleX: 0, transformOrigin: "right" }, "reveal")
      // tl.from(selector1, 1, { opacity: 0 }, "reveal")

        tl.to(selector1, 1, {drawSVG:true})

      const svgText = anime.timeline({

        endDelay: 0,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
      })

        .add({ targets: ['.large-select-1',],
          //points: '80.1,0 43.7,48.2 34.3,48.2 31.9,24.9 0,62.7 30.8,17.3 39.3,17.3 42.3,42.3 ',
          baseFrequency: 3,

        })
        .add({ targets: '.large-select-1',  fill: '#FFFFFF' }, 0)
        .add({ targets: '.large-select-0',  fill: '#d5d7d9' }, 0)

    },[])



    //render() {
        return (
          <div className="loaderContainer" >
            <div className="small-loading-icon-container" style={{ width: '200px', height: '50px', top: '20px' }}>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="200px"
                   height="50px" viewBox="0 0 138.4 35.5"  >
                <g>
                    <polygon className="large-select-0" points="65.4,12.3 65.2,17.2 67.8,17.2 67.8,12.4 66.6,13.2 	"/>
                                  <polygon className="large-select-1" points="74.2,10.8 69.8,11.6 72.9,13.1 76,11.6 	"/>
                                  <polygon className="large-select-1" points="59.1,10.8 63.4,11.6 60.2,13.1 57.3,11.6 	"/>
                                  <polygon className="large-select-1" points="72.4,0 68.6,0 71,2.8 	"/>
                                  <polygon className="large-select-1" points="74.3,0.5 72.9,3.1 75.7,3.1 	"/>
                                  <path className="large-select-1" d="M75.8,5h-0.4c-0.3,0-0.5,0-0.9,0c-0.7,0-1.6,0-2.2,0c-0.2,0.3-0.4,0.7-0.7,1.3c-0.7,1.3-1.7,3.2-2.5,4.7l2.5-2
                      l4-3.1L75.8,5z"/>
                                  <polygon className="large-select-1" points="70.1,5 68.5,5 67.9,5 67.5,5 65.7,5 65.2,5 64.7,5 63.2,5 63.7,6.1 64.6,7.8 65.1,8.7 65.5,9.5
                      66.6,11.7 66.6,11.6 66.7,11.7 67.7,9.7 68.1,8.9 68.5,8 69.5,6.1 	"/>
                                  <polygon className="large-select-1" points="64.7,0 60.8,0 62.3,2.8 	"/>
                                  <polygon className="large-select-1" points="59,0.5 57.5,3.1 60.3,3.1 	"/>
                                  <path className="large-select-1" d="M60.9,5c-0.6,0-1.5,0-2.2,0c-0.4,0-0.7,0-0.9,0h-0.4l0.3,0.9l3.1,2.4l3.4,2.6c-0.8-1.5-1.9-3.5-2.6-4.8
                      C61.3,5.6,61.1,5.2,60.9,5z"/>
                                  <polygon className="large-select-1" points="64.8,3.1 65.2,3.1 65.7,3.1 67.4,3.1 67.9,3.1 68.4,3.1 68.8,3.1 68.4,2.5 67.8,1.9 66.7,0.3
                      66.6,0.4 66.6,0.3 65.4,1.9 64.8,2.6 64.3,3.1 	"/>
                  </g>
                                <g>
                    <g>
                      <polygon className="large-select-0" points="27.7,11.7 29.7,9.2 23.8,9.2 23.8,11.3 		"/>
                      <polygon className="large-select-0" points="22.9,14.9 22.9,12.2 22.9,11.7 22.9,11.1 22.9,9.2 22.9,8.8 22.9,8.2 22.9,2.7 35.2,3.7 37.3,1
                        19.9,1 19.9,8.5 19.9,9.1 19.9,9.7 19.9,10.8 19.9,11.4 19.9,11.8 19.9,16.6 36,16.6 37.5,14.9 		"/>
                    </g>
                                  <path className="large-select-0" d="M10.6,0.4H1.7L0,2.7h10.7c6.5,0,6,1.9,6,3.1c0,1.4-0.8,2.2-2.3,3c-1.3,0.6-3,0.9-4.9,0.9L3.3,9.6L3.2,5.8H0.8
                      l0.1,10.9h2.5l-0.1-5.2h4.5c0.2,0,0.4,0,0.6,0h0.2l5.6,5.2h3l-5.6-5.3l1.1-0.1c4.2-0.4,6.2-2.3,6.2-5.8C18.9,1.2,14.6,0.4,10.6,0.4
                      z"/>
                                  <g>
                      <path className="large-select-0" d="M44,11.6h6.5l1.7-1.9h-6.5C45.5,9.7,44.7,10.5,44,11.6z"/>
                                    <polygon className="large-select-0" points="40.1,16.8 42.9,16.8 42.9,2.7 53.5,3.7 55.7,1.1 40.1,1.1 		"/>
                    </g>
                  </g>
                                <g>
                    <g>
                      <polygon className="large-select-0" points="106.9,11.7 108.9,9.2 103.1,9.2 103.1,11.3 		"/>
                      <polygon className="large-select-0" points="102.2,14.9 102.2,12.2 102.2,11.7 102.2,11.1 102.2,9.2 102.2,8.8 102.2,8.2 102.2,2.7 114.5,3.7
                        116.6,1 99.2,1 99.2,8.5 99.2,9.1 99.2,9.7 99.2,10.8 99.2,11.4 99.2,11.8 99.2,16.6 115.3,16.6 116.7,14.9 		"/>
                    </g>
                                  <polygon className="large-select-0" points="94,0.9 94,14.1 81.7,0.9 79.3,0.9 79.3,16.6 81.6,16.6 81.6,5.4 92.7,16.6 96.3,16.6 96.3,0.9 	"/>
                                  <path className="large-select-0" d="M135.6,3c-1.7-1.2-4.2-1.7-7.3-1.7h-8.6V13l2.8-1.4V3.3h5.8c3.4,0,8.1,0.1,8.1,5c0,3.5-1.1,5.7-7.7,6.5
                      c-3,0.1-9.8,0.1-9.8,0.1l1.6,1.9h4.7c6.1-0.2,13.3,0.3,13.3-8.3C138.4,6,137.4,4.1,135.6,3z"/>
                  </g>
                                <g>
                    <path className="large-select-0" d="M14.1,26.9c0,2.2,0,2.8-0.2,3.4c-0.3,1.2-0.6,1.7-1.7,2c-0.7,0.2-2.3,0.4-4.7,0.4c-2.2,0-4-0.1-4.8-0.2
                      c-1.1-0.2-1.9-0.8-2.1-1.8c-0.2-0.8-0.4-2.2-0.4-3.7c0-1.5,0.1-2.9,0.4-3.7c0.3-1.1,1-1.6,2.2-1.8c0.9-0.2,2.2-0.2,4.1-0.2
                      c1.5,0,3.9,0.1,4.7,0.2c1,0.2,1.9,0.7,2.2,1.4c0.3,0.5,0.3,0.9,0.4,1.9h-1.5c0-1-0.1-1.4-0.4-1.7c-0.3-0.3-0.7-0.4-1.9-0.4
                      c-0.6-0.1-2.1-0.1-3-0.1c-3.1,0-4.4,0.2-4.8,0.5c-0.4,0.4-0.5,1.3-0.5,3.6c0,1.5,0.1,2.9,0.2,3.3c0.3,1.1,0.8,1.2,6.1,1.2
                      c2.7,0,3.6-0.2,4-0.6c0.3-0.4,0.4-0.8,0.4-2.4H6.7v-1.3L14.1,26.9L14.1,26.9z"/>
                                  <path className="large-select-0" d="M16.6,24.7v1.1h0.1c0.2-0.4,0.4-0.6,0.9-0.8c0.4-0.3,1.2-0.4,2.7-0.4c1.5,0,2.2,0.2,2.7,0.6
                      c0.5,0.4,0.8,1.2,0.8,2.2v0.8h-1.3v-0.5c0-1.6-0.4-2-2.3-2c-3,0-3.5,0.4-3.5,3.2v3.7h-1.3v-7.9L16.6,24.7L16.6,24.7z"/>
                                  <path className="large-select-0" d="M24.6,27.1v-0.4c0-1.6,1-2.2,4.4-2.2c2.7,0,3.6,0.2,4.2,0.9c0.5,0.5,0.6,1.1,0.6,3v4.1h-1.3v-0.9h-0.1
                      c-0.6,0.8-1.2,1-3.6,1c-2.8,0-3.5-0.1-4.1-0.5c-0.5-0.4-0.7-0.9-0.7-1.9c0-1.3,0.3-1.8,1.1-2.2c0.4-0.2,1.2-0.3,3-0.3
                      c3.2,0,3.8,0.1,4.3,0.8h0.1v-0.7c0-0.8-0.1-1.2-0.4-1.5c-0.4-0.5-1.2-0.7-3.2-0.7c-2.7,0-3.1,0.2-3.2,1.3V27L24.6,27.1L24.6,27.1z
                       M26.1,29.2c-0.4,0.2-0.5,0.5-0.5,1.1c0,0.5,0.2,0.8,0.5,1.1c0.4,0.2,1.2,0.2,3.1,0.2c2.6,0,3.2-0.3,3.2-1.3c0-1-0.7-1.3-3.5-1.3
                      C27.1,29,26.4,29,26.1,29.2z"/>
                                  <path className="large-select-0" d="M35.2,24.7h1.3v1h0.1c0.5-0.9,1.3-1.2,3.8-1.2c2.2,0,3,0.2,3.8,0.5c1,0.6,1.4,1.6,1.4,3.5
                      c0,1.8-0.4,2.9-1.4,3.5c-0.6,0.4-1.7,0.5-3.9,0.5c-2.2,0-3-0.2-3.6-1.1h-0.1v3.8h-1.3L35.2,24.7L35.2,24.7z M37.3,26.2
                      c-0.6,0.4-0.8,1.1-0.8,2.4c0,2.6,0.5,3,3.7,3c1.9,0,2.7-0.1,3.1-0.4c0.6-0.4,0.8-1.1,0.8-2.5c0-2.5-0.5-3-3.5-3
                      C38.6,25.7,37.7,25.8,37.3,26.2z"/>
                                  <path className="large-select-0" d="M47.9,21.2v4.7H48c0.5-1,1.4-1.3,4-1.3c2.2,0,3.1,0.2,3.8,0.9c0.5,0.5,0.6,1.2,0.6,3v4.1h-1.3V29
                      c0-1.6-0.1-2.1-0.4-2.5c-0.4-0.5-1.2-0.7-2.9-0.7c-1.9,0-3,0.3-3.4,0.9c-0.4,0.4-0.5,0.8-0.5,2.3v3.7h-1.3V21.2H47.9z"/>
                                  <path className="large-select-0" d="M59.1,21.2v1.4h-1.3v-1.4H59.1z M59.1,24.7v7.9h-1.3v-7.9H59.1z"/>
                                  <path className="large-select-0" d="M70.2,29.8c0,1.1-0.2,1.6-0.6,2.1c-0.6,0.6-1.5,0.9-3.5,0.9c-2.7,0-3.9-0.2-4.5-0.7c-0.8-0.5-1.2-1.7-1.2-3.3
                      c0-1.9,0.4-3,1.3-3.5c0.7-0.4,1.6-0.5,3.6-0.5c2.5,0,3.6,0.2,4.2,0.9c0.4,0.4,0.6,1,0.7,2.1H69c0-1.4-0.7-1.8-3.6-1.8
                      c-1.5,0-2.3,0.1-2.8,0.4c-0.5,0.4-0.8,1.2-0.8,2.6c0,1.3,0.3,2.2,1,2.5c0.4,0.3,1.4,0.4,3.1,0.4c2.5,0,3.1-0.4,3.1-1.9L70.2,29.8
                      L70.2,29.8z"/>
                                  <path className="large-select-0" d="M75.6,21.2h7.8c3.5,0,4.4,0.2,5.2,1.1c0.7,0.8,1,1.9,1,4.6c0,2.5-0.4,3.9-1.2,4.7c-0.8,0.8-1.7,0.9-4.8,0.9h-8
                      C75.6,32.5,75.6,21.2,75.6,21.2z M83.5,31.3c2.2,0,2.2,0,2.9-0.2c1.3-0.3,1.7-1.5,1.7-4.3c0-1.8-0.3-3-0.7-3.5
                      c-0.4-0.4-0.9-0.6-1.8-0.7c-0.4,0-0.8,0-2.2,0h-6.3v8.7H83.5z"/>
                                  <path className="large-select-0" d="M100.8,30.2c-0.1,1.9-1.2,2.5-4.4,2.5c-2.6,0-3.9-0.2-4.6-0.7c-0.8-0.5-1.2-1.6-1.2-3.3c0-1.9,0.4-3,1.4-3.5
                      c0.6-0.4,1.5-0.5,3.5-0.5c2.7,0,3.7,0.2,4.4,1.1c0.6,0.7,0.8,1.4,0.8,3.3H92c0,1,0.2,1.6,0.5,2.1c0.4,0.5,1.3,0.6,3.7,0.6
                      c1.8,0,2.5-0.2,2.9-0.6c0.2-0.2,0.3-0.4,0.3-0.8L100.8,30.2L100.8,30.2z M99.5,27.9c0-1.7-0.8-2.2-3.9-2.2c-2.7,0-3.4,0.4-3.5,2.2
                      H99.5z"/>
                                  <path className="large-select-0" d="M103.1,29.8V30c0,0.7,0.1,1,0.4,1.2c0.4,0.4,1,0.4,3.5,0.4c2.2,0,2.8-0.1,3.1-0.4c0.3-0.2,0.4-0.4,0.4-0.9
                      c0-0.9-0.5-1.3-2.1-1.3c-0.4,0-0.6,0-3.6,0c-2.1,0-2.8-0.6-2.8-2.2c0-0.8,0.3-1.4,0.8-1.8c0.5-0.4,1.7-0.5,4.1-0.5
                      c2.7,0,3.8,0.3,4.3,1c0.3,0.4,0.4,0.6,0.4,1.5h-1.3c0-0.5-0.1-0.8-0.3-1c-0.4-0.4-1.3-0.4-3.1-0.4c-1.7,0-2.8,0.1-3.1,0.4
                      c-0.3,0.2-0.4,0.4-0.4,0.9c0,0.9,0.4,1.1,2.3,1.1c0.5,0,2.8,0,3.6,0.1c1.8,0.1,2.5,0.7,2.5,2.3c0,1-0.3,1.5-0.9,1.9
                      c-0.5,0.4-1.7,0.4-4,0.4c-2.6,0-3.7-0.2-4.3-0.5c-0.6-0.4-0.8-0.9-0.8-1.9v-0.4H103.1z"/>
                                  <path className="large-select-0" d="M114.2,21.2v1.4h-1.3v-1.4H114.2z M114.2,24.7v7.9h-1.3v-7.9H114.2z"/>
                                  <path className="large-select-0" d="M125.6,24.7v5.9c0,1.2-0.1,2.2-0.2,2.8c-0.2,0.8-0.8,1.5-1.6,1.8c-0.7,0.3-1.6,0.3-3.5,0.3
                      c-2.6,0-3.8-0.3-4.4-1.2c-0.3-0.4-0.4-0.7-0.4-1.4h1.3c0.1,1.2,0.8,1.4,3.3,1.4c2.4,0,3.3-0.2,3.7-0.7c0.4-0.4,0.4-0.8,0.5-2.4
                      h-0.1c-0.7,0.8-1.3,1-3.9,1c-2.2,0-3.2-0.2-3.9-0.6c-0.8-0.6-1.3-1.6-1.3-3.2c0-1.8,0.5-2.9,1.6-3.4c0.7-0.4,1.6-0.4,3.7-0.4
                      c1.7,0,2.3,0.1,2.9,0.4c0.4,0.2,0.5,0.4,0.8,0.7h0.1v-1h1.3V24.7z M117.7,26.1c-0.6,0.3-0.9,1.1-0.9,2.4c0,1.3,0.4,2.1,1.1,2.4
                      c0.4,0.2,1.3,0.3,2.8,0.3c1.7,0,2.5-0.1,3-0.4c0.6-0.4,0.8-1.1,0.8-2.4c0-2.2-0.6-2.6-3.7-2.6C119.1,25.7,118.2,25.8,117.7,26.1z"
                                  />
                                  <path className="large-select-0" d="M128.2,24.7v1.2h0.1c0.5-1,1.3-1.3,3.9-1.3c2.2,0,3.2,0.2,3.8,0.9c0.4,0.5,0.6,1.2,0.6,2.7v4.4h-1.3v-3.9
                      c0-1.4-0.1-1.9-0.4-2.3c-0.4-0.5-1.2-0.7-2.9-0.7c-2,0-3,0.3-3.5,0.9c-0.4,0.4-0.4,0.8-0.4,2.3v3.7h-1.3v-7.9L128.2,24.7
                      L128.2,24.7z"/>
                  </g>
                  </svg>
            </div>
          </div>
        )
    //}
}

export default BigLogoLoader
