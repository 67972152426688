import * as ROUTE_META from './data.json'
import *  as APP_CONFIG from '../config'
const metadata = ROUTE_META.metadata

export const headerLinks = [
    {
        internalLink:true,
        linkDetails:{
            label:metadata.home.linkName,
            url:metadata.home.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.services.linkName,
            url:metadata.services.route,
        }
    },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.products.linkName,
    //         url:metadata.products.route,
    //     }
    // },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.packages.linkName,
            url:metadata.packages.route,
        }
    },

    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.tools.linkName,
    //         url:metadata.tools.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.forms.linkName,
    //         url:metadata.forms.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.specialOffers.linkName,
    //         url:metadata.specialOffers.route,
    //     }
    // },


    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.testimonials.linkName,
    //         url:metadata.testimonials.route,
    //     }
    // },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.faqs.linkName,
            url:metadata.faqs.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.techDocs.linkName,
            url:metadata.techDocs.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.blogs.linkName,
            url:metadata.blogs.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.contact.linkName,
            url:metadata.contact.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.about.linkName,
            url:metadata.about.route,
        }
    },


    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.glossary.linkName,
    //         url:metadata.glossary.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.portfolio.linkName,
    //         url:metadata.portfolio.route,
    //     }
    // }
]




const renderInternalNavLinks = () =>{

    //render each internal link that is in the headerLinks array
    let footerNavLinks =[]
    for (const navLink of headerLinks) {
        if(navLink.internalLink === true) footerNavLinks.push({value: {...navLink}, type:'link'})
    }
    // console.log(footerNavLinks)
    return footerNavLinks
}


export const footerContent = {
    columns:[
        {
            content:[
                {
                    type:'title',
                    value:APP_CONFIG.WEBSITE_NAME
                },
                ...renderInternalNavLinks()
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Print Design'
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Product Packaging',
                            url:`${metadata.services.route}/product-packaging-design`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Business Cards',
                            url:`${metadata.services.route}/business-cards`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Corporate Documentation',
                            url:`${metadata.services.route}/corporate-documents-reports`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Retail Signage',
                            url:`/${metadata.services.route}/retail-signage`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Logo and Branding',
                            url:`${metadata.services.route}/digital-identity-and-branding`
                        }
                    }
                }
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Digital Services'
                },
                ,
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Education and Digital Training',
                            url:`${metadata.services.route}/education-and-digital-training`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Website Design',
                            url:`${metadata.services.route}/website-design`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Digital Food Menus and Signage',
                            url:`${metadata.services.route}/digital-food-menus-and-signage`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Software & App Design',
                            url:`${metadata.services.route}/software-and-application-graphic-design`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Digital Advertising and Marketing',
                            url:`${metadata.services.route}/digital-advertising-and-marketing`
                        }
                    }
                }
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Design Blogs'
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Graphic Design of Tomorrow',
                            url:`${metadata.blogs.route}/graphic-design-of-tomorrow`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'2020 Graphic Design',
                            url:`${metadata.blogs.route}/2020-graphic-design`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'COVID 19 Digital Design Demand',
                            url:`${metadata.blogs.route}/covid-19-digital-design`
                        }
                    }
                },

                {
                    type:'img',
                    specialClass:'footerLogo',
                    value:{
                        src: `/${APP_CONFIG.FOOTER_IMAGE}`,
                        title: APP_CONFIG.WEBSITE_NAME,
                        alt: APP_CONFIG.WEBSITE_NAME
                    }
                }
            ]
        }
    ]
}
