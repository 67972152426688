import React, {Fragment} from 'react'
import config from '../../../../config.json'

require ('./ContactIcons.scss')

const ContactIcons = props =>{

  const {primaryMobile, email} = props

  return(
    <Fragment>
      {primaryMobile && (
        <div className="contactTile">
          <div className="">
            <img src="../../../../assets/img/icons/phone.svg" />
          </div>
          <div className="">
            <a href={`tel:${config.CONTACT_INFORMATION.PRIMARY_MOBILE}`} title={`Email ${config.WEBSITE_NAME}`} >{config.CONTACT_INFORMATION.PRIMARY_MOBILE}</a>
          </div>
        </div>
      )}
      {email && (
        <div className="contactTile">
          <div className="">
            {/*<i className="fas fa-envelope-open-text fa-2x"></i>*/}
            <img src="../../../../assets/img/icons/mail.svg" />
          </div>
          <div className="">
            <a href={`mailto:${config.CONTACT_INFORMATION.EMAIL}`} title={`Email ${config.WEBSITE_NAME}`} >{config.CONTACT_INFORMATION.EMAIL}</a>
          </div>
        </div>
      )}
    </Fragment>
    )
}

export default ContactIcons;
