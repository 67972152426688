import styled, { css } from 'styled-components'
import {headerBackgroundColor} from '../../../../styles/base/base-colors.scss';
import {websitePageWidth} from '../../../../styles/base/base-layout.scss';

//todo move these out into global
const size = {
      mobileS: '320px',
      mobileM: '375px',
      mobileL: '425px',
      tablet: '710px',
      laptop: '1070px',
      laptopL: '1440px',
      websitePageWidth:websitePageWidth,
      desktop: '2560px'
}

const device = {
      mobileS: `(max-width: ${size.mobileS})`,
      mobileM: `(max-width: ${size.mobileM})`,
      mobileL: `(max-width: ${size.mobileL})`,
      tablet: `(max-width: ${size.tablet})`,
      laptop: `(max-width: ${size.laptop})`,
      laptopL: `(max-width: ${size.laptopL})`,
      websitePageWidth: `(max-width: ${size.websitePageWidth})`,
      desktop: `(max-width: ${size.desktop})`,
      desktopL: `(max-width: ${size.desktop})`
};

// $color: $secondaryThemeColor;
//background colors
//$headerBackgroundColor: #f9f9f9;


// $buttonBorderRadius:5px;
// $buttonBackgroundColor:#f5f5ff;

//icon params
const heightIcon = '18px'
//line params
const widthLine = '32px'
const heightLine = '4px'
const line1Transform = `translateY(${parseInt(heightLine) / 2 * -1}px)`
const line3Transform = `translateY(${parseInt(heightLine) / 2}px)`
const line1ActiveTransform = `translateY(${parseInt(heightIcon) / 2}px) translateX(0) rotate(45deg)`
const line3ActiveTransform = `translateY(${parseInt(heightIcon) / 2 * -1}px) translateX(0) rotate(${45 * -1}deg)`

// $lineColor:#95a5a6;
// $lineHoverColor:#95a5a6;

//animation params
// $transition-time: 0.4s;
// $rotation: 45deg;
// $translateY: ($height-icon / 2);
// $translateX: 0;
//
// //icon offset params
// $topOffset:12px;
// $leftOffset:10px;
// $rightOffset:10px;

export const MenuButtonStyle = styled.div`

#menuButton{
height: 46px;
  width: 52px;
  position: absolute;
  //right: $rightOffset;
  left: 10px;
  top: 12px;
  overflow: hidden;
  //background-color:red;
  border-radius: 5px;
  //display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  cursor: pointer;
  z-index: 2;
  //transform:scale(0.6);

  #hamburger-icon {
    width:${widthLine};
    height:${heightIcon};
    position:relative;
    //fixes vertical align
    top:-${(parseInt(heightLine) / 2)}px;
    display:block;

    .line {
      display:block;
      background:${props => props.lineColor };
      width:${widthLine};
      height:${heightLine};
      position:absolute;
      left:0;
      border-radius:${(parseInt(heightLine)/ 2)}px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;

      &.line-1 {
        top:0;
      }
      &.line-2 {
        top:50%;
      }
      &.line-3 {
        top:100%;
      }
    }
    &:hover, &:focus {
      .line-1 {
        transform: ${line1Transform};
        -webkit-transform: ${line1Transform};
        -moz-transform: ${line1Transform};
      }
      .line-3 {
        transform: ${line3Transform};
        -webkit-transform: ${line3Transform};
        -moz-transform: ${line3Transform};
      }
    }
    &.active {
      .line-1 {
        transform: ${line1ActiveTransform};
        -webkit-transform: ${line1ActiveTransform};
        -moz-transform: ${line1ActiveTransform};
      }
      .line-2 {
        opacity:0;
      }
      .line-3 {
        transform: ${line3ActiveTransform};
        -webkit-transform: ${line3ActiveTransform};
        -moz-transform: ${line3ActiveTransform};
      }
    }
  }

  h1 {
    text-transform:uppercase;
  }
  a {
    text-decoration:none;
    color:${props => props.lineColor };
    //margin: 0.5em 1.5em;
    display:inline-block;
    &:hover, &:focus {
      color:${props => props.lineColor };
    }
  }
  
 @media ${device.websitePageWidth} { 
          display: flex;
  }

  //starts to act weird beyond this width so change position
  @media ${device.mobileS}{
        //transform: scale(0.6);
        z-index: 3;
        top: 12px;
        background-color: rgba(0,0,0,0);
        right: 0;
  }

}
  
    
`
