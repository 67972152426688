import React, { Suspense, Fragment } from 'react'
import Loading from './components/common/loading'
import Animate from 'animate.css-react'
import {Helmet} from "react-helmet";
import {metadata} from "./app-config"
import Header from './components/common/header/header'

const LazyHOC = props =>{
  return(

    <Fragment>
      {!props.noHeader && (
        <Header
          transparentHeader={props.transparentHeader || false}
        />
      )}
      {props.children}
    </Fragment>


  );
}

const HelmetHOC = props =>{
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
    </Helmet>
  )
}

const LazyLanding = React.lazy(() => import('./pages/home'));
const LazyServices = React.lazy(() => import('./pages/services'));
const LazyServicePage = React.lazy(() => import('./pages/services/service'));
const LazyGalleryPage = React.lazy(() => import('./pages/gallery'));
const LazyContact = React.lazy(() => import('./pages/contact'));
const LazyAbout = React.lazy(() => import('./pages/about'));
const LazyTechnicalDocsPage = React.lazy(() => import('./pages/technical-docs'));
const LazyTechnicalDocPage = React.lazy(() => import('./pages/technical-docs/technical-doc'));
const LazyFaqsPage = React.lazy(() => import('./pages/faqs'));
const LazyFaqPage = React.lazy(() => import('./pages/faqs/faq'));
const LazyBlogsPage = React.lazy(() => import('./pages/blogs'));
const LazyBlogPage = React.lazy(() => import('./pages/blogs/blog'));
const LazyProductsPage = React.lazy(() => import('./pages/products'));
const LazyProductPage = React.lazy(() => import('./pages/products/product'));
const LazyTestimonialsPage = React.lazy(() => import('./pages/testimonials'));
const LazySearchPage = React.lazy(() => import('./pages/search'));
const LazyPackagesPage = React.lazy(() => import('./pages/packages'));
const LazyPackagePage = React.lazy(() => import('./pages/packages/package'));
const LazyGlossaryPage = React.lazy(() => import('./pages/glossary'));
const LazyPortfolioPage = React.lazy(() => import('./pages/portfolio'));
const LazySpecialOffersPage = React.lazy(() => import('./pages/special-offers'));
const LazySpecialOfferPage = React.lazy(() => import('./pages/special-offers/special-offer'));
const LazyFormsPage = React.lazy(() => import('./pages/forms'));
const LazyFormPage = React.lazy(() => import('./pages/forms/form'));
const LazyToolsPage = React.lazy(() => import('./pages/tools'));
const LazyToolPage = React.lazy(() => import('./pages/tools/tool'));

function Landing (){
 return(
   <Suspense fallback={<Loading message={metadata.home.description} />}>
     {HelmetHOC(metadata.home)}
     <LazyHOC
     transparentHeader
     noHeader
     ><LazyLanding /></LazyHOC>
   </Suspense>
 )
}

function Services (){
  return(
    <Suspense fallback={<Loading message={metadata.services.description} />}>
      {HelmetHOC(metadata.services)}
      <LazyHOC><LazyServices /></LazyHOC>
    </Suspense>
  )
}
function GalleryPage (){
  return(
    <Suspense fallback={<Loading message={metadata.gallery.description} />}>
      {HelmetHOC(metadata.gallery)}
      <LazyHOC><LazyGalleryPage /></LazyHOC>
    </Suspense>
  )
}
function Contact (){
  return(
    <Suspense fallback={<Loading message={metadata.contact.description} />}>
      {HelmetHOC(metadata.contact)}
      <LazyHOC><LazyContact /></LazyHOC>
    </Suspense>
  )
}
function About (){
  return(
    <Suspense fallback={<Loading message={metadata.about.description} />}>
      {HelmetHOC(metadata.about)}
      <LazyHOC><LazyAbout /></LazyHOC>
    </Suspense>
  )
}
function TechnicalDocsPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.techDocs.description}  />}>
      {HelmetHOC(metadata.techDocs)}
      <LazyHOC><LazyTechnicalDocsPage /></LazyHOC>
    </Suspense>
  )
}
function TechnicalDocPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.techDoc.description}  />}>
      {HelmetHOC(metadata.techDoc)}
      <LazyHOC><LazyTechnicalDocPage /></LazyHOC>
    </Suspense>
  )
}
function ServicePage (){
  return(
    <Suspense fallback={<Loading  message={metadata.service.description}  />}>
      {HelmetHOC(metadata.service)}
      <LazyHOC><LazyServicePage /></LazyHOC>
    </Suspense>
  )
}
function FaqsPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.faqs.description}  />}>
      {HelmetHOC(metadata.faqs)}
      <LazyHOC><LazyFaqsPage /></LazyHOC>
    </Suspense>
  )
}
function FaqPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.faq.description}  />}>
      {HelmetHOC(metadata.faq)}
      <LazyHOC><LazyFaqPage /></LazyHOC>
    </Suspense>
  )
}
function BlogsPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.blogs.description}  />}>
      {HelmetHOC(metadata.blogs)}
      <LazyHOC><LazyBlogsPage /></LazyHOC>
    </Suspense>
  )
}
function BlogPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.blog.description}  />}>
      {HelmetHOC(metadata.blog)}
      <LazyHOC><LazyBlogPage /></LazyHOC>
    </Suspense>
  )
}
function ProductsPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.products.description}  />}>
      {HelmetHOC(metadata.products)}
      <LazyHOC><LazyProductsPage /></LazyHOC>
    </Suspense>
  )
}
function ProductPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.product.description}  />}>
      {HelmetHOC(metadata.product)}
      <LazyHOC><LazyProductPage /></LazyHOC>
    </Suspense>
  )
}
function TestimonialsPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.testimonials.description}  />}>
      {HelmetHOC(metadata.testimonials)}
      <LazyHOC><LazyTestimonialsPage /></LazyHOC>
    </Suspense>
  )
}
function SearchPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.search.description}  />}>
      {HelmetHOC(metadata.search)}
      <LazyHOC><LazySearchPage /></LazyHOC>
    </Suspense>
  )
}
function PackagesPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.packages.description}  />}>
      {HelmetHOC(metadata.packages)}
      <LazyHOC><LazyPackagesPage /></LazyHOC>
    </Suspense>
  )
}
function PackagePage (){
  return(
    <Suspense fallback={<Loading  message={metadata.package.description}  />}>
      {HelmetHOC(metadata.package)}
      <LazyHOC><LazyPackagePage /></LazyHOC>
    </Suspense>
  )
}
function GlossaryPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.glossary.description}  />}>
      {HelmetHOC(metadata.glossary)}
      <LazyHOC><LazyGlossaryPage /></LazyHOC>
    </Suspense>
  )
}
function PortfolioPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.portfolio.description}  />}>
      {HelmetHOC(metadata.portfolio)}
      <LazyHOC><LazyPortfolioPage /></LazyHOC>
    </Suspense>
  )
}
function SpecialOffersPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.specialOffers.description}  />}>
      {HelmetHOC(metadata.specialOffers)}
      <LazyHOC><LazySpecialOffersPage /></LazyHOC>
    </Suspense>
  )
}
function SpecialOfferPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.specialOffer.description}  />}>
      {HelmetHOC(metadata.specialOffer)}
      <LazyHOC><LazySpecialOfferPage /></LazyHOC>
    </Suspense>
  )
}
function FormsPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.forms.description}  />}>
      {HelmetHOC(metadata.forms)}
      <LazyHOC><LazyFormsPage /></LazyHOC>
    </Suspense>
  )
}
function FormPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.form.description}  />}>
      {HelmetHOC(metadata.form)}
      <LazyHOC><LazyFormPage /></LazyHOC>
    </Suspense>
  )
}
function ToolsPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.tools.description}  />}>
      {HelmetHOC(metadata.tools)}
      <LazyHOC><LazyToolsPage /></LazyHOC>
    </Suspense>
  )
}
function ToolPage (){
  return(
    <Suspense fallback={<Loading  message={metadata.tool.description}  />}>
      {HelmetHOC(metadata.tool)}
      <LazyHOC><LazyToolPage /></LazyHOC>
    </Suspense>
  )
}


export {
  Landing,
  Services,
  Contact,
  About,
  GlossaryPage,
  SearchPage,
  GalleryPage,
  TestimonialsPage,
  BlogsPage,
  BlogPage,
  FaqsPage,
  FaqPage,
  ProductsPage,
  ProductPage,
  PackagesPage,
  PackagePage,
  ServicePage,
  TechnicalDocsPage,
  TechnicalDocPage,
  PortfolioPage,
  SpecialOffersPage,
  SpecialOfferPage,
  FormsPage,
  FormPage,
  ToolsPage,
  ToolPage
}
