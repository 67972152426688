import React, {Fragment} from 'react'
import ContactIcons from '../contact-icons'

require ('./HeaderContactDetails.scss')

const HeaderContactDetails = props =>{

  const {primaryMobile, email} = props

  //todo mahmod - change contact icons
  return(
    <div className="contactTileInHeader">
      {primaryMobile && (
        <ContactIcons primaryMobile={primaryMobile} />
      )}
      {email && (
        <ContactIcons email={email} />
      )}
    </div>
    )
}

export default HeaderContactDetails;
